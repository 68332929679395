import React from 'react';
import pageStyle from '../../assets/css/page.module.css';
import style from '../../assets/css/main.module.css';
import Menu from '../../components/menu';
import Footer from '../../components/footer';
import HeroPage from '../../components/hero.page';
import Hamburger from '../../components/hamburger';

import imgSEO from '../../assets/img/services/referencement.jpg';
import SimilarCard from '../../components/similar.card';
import similar1 from '../../assets/img/services/thumbnail/seo.jpg';
import similar3 from '../../assets/img/services/thumbnail/contenu.jpg';
import similar2 from '../../assets/img/services/thumbnail/pub.jpg';
import similar4 from '../../assets/img/services/thumbnail/maintenance.jpg';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import CtaSection from '../../components/cta.section';
import cta from '../../assets/img/illustrations/search.svg';

export default () => (
  <div id="top">
    <Menu />
    <SEO
      title="Référencement | Agence digitale | MDS Digital Agency"
      description="Nous utilisons les dernières innovations en matière de référencement pour rendre votre site internet visible sur les moteurs de recherche."
      keywords="référencement"
    />
    <div className={style.menu_mobile}>
      <Hamburger />
    </div>
    <HeroPage img={imgSEO} />

    <article className={pageStyle.article}>
      <h2>Référencement</h2>
      <p>
        Vous souhaitez donner plus de visibilité à votre site internet ? Être présent en première
        page des résultats sur Google ? Augmenter le nombre de visiteurs sur votre site web ?
      </p>
      <p>
        Nous utilisons les dernières innovations en matière de référencement pour rendre votre site
        internet visible sur les moteurs de recherche.
      </p>
      <p>
        Suivant vos objectifs et votre budget, nous mettons en place une stratégie de visibilité
        efficace pour améliorer le référencement de votre site web.
      </p>
      <p>La stratégie de référencement de votre site web s’articule suivant 2 axes:</p>
      <ol>
        <li>
          le référencement{' '}
          <strong>
            <Link to="/services/referencement/naturel/">naturel</Link>
          </strong>{' '}
          (Search Engine Optimisation ou SEO)
        </li>
        <li>
          le référencement{' '}
          <strong>
            <Link to="/services/referencement/payant/">payant</Link>
          </strong>{' '}
          (Search Engine Advertising ou SEA ou Adwords)
        </li>
      </ol>
      <CtaSection
        img={cta}
        ctaText="Devis en ligne"
        title="A la recherche d’une stratégie de référencement sur mesure ?"
        subtitle="Bénéficiez d’une plus grande visibilité sur le web grâce à notre forte expertise."
      />
    </article>
    <div className={pageStyle.footer}>
      <div className={pageStyle.article}>
        <h3>Autres services</h3>
        <div className={pageStyle.footer_flex}>
          <SimilarCard
            text="Référencement naturel (SEO)"
            url="/services/referencement/naturel/"
            img={similar1}
          />
          <SimilarCard
            text="Référencement payant (SEA)"
            url="/services/referencement/payant/"
            img={similar2}
          />
          <SimilarCard
            text="Mise à jour de contenu"
            url="/services/mise-a-jour-contenu/"
            img={similar3}
          />
          <SimilarCard text="Maintenance" url="/services/maintenance/" img={similar4} />
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
